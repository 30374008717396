<template>
  <div class="box">
    <div>
      <img src="@/assets/img/logo.png" class="box-img" alt="logo" />
      <van-form class="form" @submit="onSubmit">
        <!-- 输入手机号，调起手机号键盘 -->
        <van-field class="setBorder" v-model="form.phone" type="tel" clearable
          :border="false" placeholder="请填写手机号" :rules="[
            {
              required: true,
              validator: phoneTest,
              message: '请填写正确手机号'
            }
          ]" />
        <div class="yzm-box">
          <van-field class="setBorder" v-model="form.verificationCode"
            type="digit" :border="false" maxlength="6" autocomplete="off"
            clearable placeholder="请输入短信验证码" :rules="[
              {
                required: true,
                message: '验证码不正确',
                trigger: 'onSubmit'
              }
            ]" />
          <van-button class="yzm-btn" :disabled="yzmText !== '获取验证码'"
            @click="sendYzm">{{ yzmText }}</van-button>
        </div>
        <van-button class="submit" native-type="submit"> 登录 </van-button>
        <router-link to="/register" v-slot="{}">
          <div class="jumpLogin van-hairline--bottom">
            还没有账号? 立即<span style="font-weight: bold">注册.</span>
          </div>
        </router-link>
        <div class="tips">
          达人平台免费注册，不会向达人收取任何费用，如遇收费问题，可以联系平台工作人员咨询！
        </div>
      </van-form>
      <div v-if="$store.state.user.platform == 'wx'" class="wxLogin"
        @click="wxLogin">
        <i class="font_family icon-weixin wxIcon"></i>
        <div>微信登录</div>
    </div>
    </div>
    <footer>
      <p class="van-hairline--bottom" @click="toLink('https://www.12377.cn')">
        中央网信办互联网违法和不良信息举报中心
      </p>
      <p class="van-hairline--bottom" @click="toLink('https://www.qinglangtianjin.com')">
        天津市互联网违法和不良信息举报中心
      </p>
    </footer>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { login, sendVerificationCode, wxLoginGetCode } from '@/api/login.js'
import $store from '@/store/index'
const route = useRoute()
const router = useRouter()
let yzmText = ref('获取验证码')
const form = reactive({
  phone: '',
  verificationCode: ''
})
// 校验函数返回 true 表示校验通过，false 表示不通过
const phoneTest = val => /^1?[0-9]{10}$/.test(val)
// 校验函数可以返回 Promise，实现异步校验,在这里登录并校验验证码
const onSubmit = async () => {
  let res = await login(form)
  $store.dispatch('setToken', {
    token: res.token,
    wechat: res.wechat
  })
  $store.commit('setList', [
    'task',
    'bindingChannel',
    'information',
    'userAbout',
    'contact',
    'extension'
  ])
  router.push({ path: '/extract' })
}
// 发送验证码
async function sendYzm() {
  if (!phoneTest(form.phone)) {
    return Toast('手机号不合法')
  }
  await sendVerificationCode({
    phone: form.phone,
    sendChannel: 'login'
  })
  Toast('发送成功')
  yzmText.value = 60
  let st1 = setInterval(() => {
    yzmText.value--
  }, 1000)
  setTimeout(() => {
    window.clearInterval(st1)
    yzmText.value = '获取验证码'
  }, 60000)
}
// 微信登录
function wxLogin() {
  wxLoginGetCode().then(res => {
    window.location.href = res
  })

  document.querySelectorAll('p').forEach(item => {
    item.style.userSelect = 'auto'
  })
  document.querySelector('.ManuscriptIntro-root-veKLT').style.userSelect =
    'auto'
}
function toLink(href) {
  window.location.href = href
}
</script>
<style scoped lang="less">
.box {
  min-height: 100%;
  padding: 0px 34px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .box-img {
    width: 750px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .form {
    width: 100%;
    .setBorder {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
    }
    .yzm-box {
      display: flex;
      justify-content: space-between;
      align-items: top;
      margin-top: 20px;
      .yzm-btn {
        background: #fe774c;
        width: 310px;
        height: 80px;
        border-radius: 10px;
        color: white;
        margin-left: 40px;
        margin-top: -2px;
        letter-spacing: 2px;
        padding: 0px 20px;
      }
    }
    .submit {
      width: 100%;
      background: #fe774c;
      border-radius: 10px;
      color: white;
      letter-spacing: 4px;
      font-size: 34px;
      margin-top: 50px;
    }
    .jumpLogin {
      display: inline-block;
      letter-spacing: 4px;
      color: #fe774c;
      padding-bottom: 6px;
      margin-top: 50px;
      font-size: 28px;
    }
    .tips {
      margin-top: 40px;
      text-align: left;
      font-size: 28px;
      color: #999;
    }
  }
  .wxLogin {
    margin: 90px 0px 0px;
    .wxIcon {
      font-size: 64px;
      border: 1px rgb(241, 241, 241) solid;
      border-radius: 100%;
      padding: 10px 12px;
      color: #09bb07;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 1px;
    }
    div {
      margin-top: 22px;
      font-size: 24px;
      color: #666666;
    }
  }
}
.van-hairline--bottom::after {
  border-color: #fe774c;
}
/deep/ .van-field__body {
  border: 2px solid #d6d6d6 !important;
  padding: 12px 24px;
  border-radius: 10px;
}
footer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  color: #fe774c;
  p {
    margin: 3px 0;
  }
}
</style>
